import axios from '@/request/http'
import base from '@/request/base'
import QS from 'querystring'

const finance = {
  recharge() {
    return axios.post(`${base.admin_url}/cloud/cloudfb/recharge`)
  },
  /**
   * 获取汇率和付款金额
   * @param {Number} dollar 充值金额（美元）
   */
  getRechargeRate(dollar) {
    return axios.get(`${base.admin_url}/cloud/cloudfb/recharge_rate?dollar=${dollar}`)
  },
  verify(params) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/setRealCert`, QS.stringify(params))
  },
  specialVerify(params) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/specialSetRealCert`, QS.stringify(params))
  },
  setRecharge(id) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/set_recharge`, QS.stringify({ id: id }))
  },
  rechargePay(type, dollar) {
    return axios.post(
      `${base.admin_url}/cloud/cloudfb/rechargePay`,
      QS.stringify({
        type: type,
        dollar: dollar,
      })
    )
  },
  getSpecialRecharge() {
    return axios.post(`${base.admin_url}/cloud/cloudfb/special_recharge`)
  },
  //个号充值
  specialPay(type, dollar, remark) {
    return axios.post(
      `${base.admin_url}/cloud/cloudfb/specialRechargePay`,
      QS.stringify({
        type: type,
        dollar: dollar,
        remark: remark,
      })
    )
  },
  //个号充值-通联
  //银行卡列表
  tongLianBankcard(rechargeNo) {
    return axios.post(
      `${base.admin_url}/cloud/cloudfb/special_tonglian_recharge`,
      QS.stringify({
        recharge_no: rechargeNo,
      })
    )
  },
  //银行卡解绑
  unBindTonglianBankcard(id) {
    return axios.post(
      `${base.admin_url}/cloud/cloudfb/untie_bankcard`,
      QS.stringify({
        id: id,
      })
    )
  },
  //提交银行卡
  submitTonglianBankcard(card) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/bing_bankcard`, QS.stringify(card))
  },
  //验证银行卡
  submitTonglianBankcardWithVerify(id, smscode) {
    return axios.post(
      `${base.admin_url}/cloud/cloudfb/bing_bankcard_confirm`,
      QS.stringify({
        id: id,
        smscode: smscode,
      })
    )
  },
  //提交订单
  startTongLianPay(rechargeNo, bankcardId) {
    return axios.post(
      `${base.admin_url}/cloud/cloudfb/special_tonglian_fast_pay`,
      QS.stringify({
        recharge_no: rechargeNo,
        bankcard_id: bankcardId,
      })
    )
  },
  //发送支付短信验证码
  sendConfirmTongLianSmsCode(rechargeNo, bankcardId) {
    return axios.post(
      `${base.admin_url}/cloud/cloudfb/fast_pay_set_phone`,
      QS.stringify({
        recharge_no: rechargeNo,
        bankcard_id: bankcardId,
      })
    )
  },
  //确认支付订单
  confirmTongLianPay(params) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/fast_pay_confirm`, QS.stringify(params))
  },
  submitVoucher(params) {
    return axios.post(
      `${base.admin_url}/cloud/cloudfb/submit_voucher`,
      QS.stringify({
        money: params.money ? params.money : '',
        type: params.type ? params.type : '',
        mark: params.mark ? params.mark : '',
        path: params.path ? params.path : '',
      })
    )
  },
  getThree() {
    return axios.get(`${base.admin_url}/cloud/cloudfb/three_payment_detail`)
  },
  submitThree(params) {
    return axios.post(
      `${base.admin_url}/cloud/cloudfb/three_payment`,
      QS.stringify({
        contract_pay: params.contract_pay ? params.contract_pay : '',
        pay_fee: params.pay_fee ? params.pay_fee : '',
        pay_bank: params.pay_bank ? params.pay_bank : '',
        party_img: params.party_img ? params.party_img : '',
        recharge_bank_account: params.recharge_bank_account ? params.recharge_bank_account : '',
        recharge_bank_name: params.recharge_bank_name ? params.recharge_bank_name : '',
        recharge_bank_swift: params.recharge_bank_swift ? params.recharge_bank_swift : '',
      })
    )
  },
  /**
   * Payoneer判断是否授权（type=1 判断是否授权 type=2 去授权）
   */
  judgePayoneerAuth(data) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/payoneer_auth`, data)
  },
  /**
   * 获取Payoneer支付信息
   * @param {Number} dollar
   */
  getPayoneerDetail(dollar) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/payoneer_detail`, QS.stringify({ dollar }))
  },
  /**
   * 提交Payoneer支付
   * @param {String} recharge_no 回调的订单号
   */
  submitPayoneer(recharge_no) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/PayoneerRechargePay`, QS.stringify({ recharge_no }))
  },
  /**
   * Payoneer二次验证
   * @param {String} session_id 回调的session_id
   * @param {String} response_path 回调的response_path
   * @returns
   */
  verifyPayoneer(session_id, response_path) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/payoneer_verify`, QS.stringify({ session_id, response_path }))
  },
  registerPayonner() {
    return axios.get(`${base.admin_url}/cloud/cloudfb/payoneer_link`)
  }
}

export default finance
