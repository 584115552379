<template>
    <el-dialog v-model="isDialogShow" :title="networkStatus ? 'Facebook广告账户授权' : ''" width="800px"
        :top="currentStep === 0 ? '22vh' : '10vh'" :close-on-click-modal="false" :destroy-on-close="true"
        @close="$emit('close')" center custom-class="template-login-dialog" ref="dialogRef">
        <div class="network-container" v-show="!networkStatus">
            <img src="@/assets/images/adTemplate/network.svg" alt="" />
            <span>识别到您的网络无法连接到Facebook官方平台，请检查您的网络设置，确保您能够访问外网。</span>
            <div class="refresh-button" @click="refreshNetwork">刷新</div>
        </div>
        <div v-show="networkStatus" style="padding-top: 35px">
            <div class="login-steps">
                <img src="@/assets/images/adTemplate/one-step.svg" alt="" v-if="currentStep === 0" />
                <img src="@/assets/images/adTemplate/one-step-success.svg" alt="" v-else />
                <span :class="{ 'active-step': currentStep === 0 }">选择Facebook账号</span>
                <span class="user-selected" v-if="loginStatus">已选 {{ FBUserList[userRadio]?.name }}</span>
                <div></div>
                <img src="@/assets/images/adTemplate/two-step.svg" alt="" v-if="currentStep === 0" />
                <img src="@/assets/images/adTemplate/two-step-success.svg" alt="" v-else />
                <span :class="{ 'active-step': currentStep === 1 }">选择广告账户</span>
                <span class="account-selected" v-if="loginStatus && accountRadio !== null">
                    已选 {{ FBAccountList[accountRadio]?.account_id }}
                </span>
            </div>
            <div class="login-button" v-if="!loginStatus && currentStep === 0" @click="checkNetwork">Facebook账号授权</div>
            <div class="list-container" v-else>
                <div class="title">{{ currentStep === 0 ? '已获授权用户' : '选择广告账户' }}</div>
                <div class="user-container" v-if="currentStep === 0">
                    <el-radio-group v-model="userRadio">
                        <el-radio :label="index" size="large" border v-for="(item, index) in FBUserList">
                            <div class="user-radio">
                                <img :src="item.url" alt="" />
                                <span class="nickname">{{ item.name }}</span>
                                <span class="id">ID: {{ item.id }}</span>
                                <span>广告账户个数：{{ item.account_num }}</span>
                            </div>
                        </el-radio>
                    </el-radio-group>
                </div>
                <div class="account-container" v-if="currentStep === 1">
                    <div class="search">
                        <span>广告账户</span>
                        <el-input v-model="accountSearchValue" placeholder="请输入账户ID或者账户名称" clearable size="medium" />
                        <el-button @click="resetAccountList" size="medium">重置</el-button>
                        <el-button type="primary" @click="handleAccountCurrentChange" size="medium">查询</el-button>
                    </div>
                    <div class="header">
                        <span>账户名称</span>
                        <span>账户ID</span>
                        <span>账户状态</span>
                        <span>账户余额</span>
                        <!-- <span>绑定BM</span> -->
                    </div>
                    <el-radio-group v-model="accountRadio" v-loading="loading" element-loading-text="加载中...">
                        <el-radio :label="index" size="large" border v-for="(item, index) in FBAccountList"
                            :disabled="item.account_status !== 1">
                            <div class="account-radio">
                                <span class="accountName">{{ item.name }}</span>
                                <span class="id">{{ item.account_id }}</span>
                                <span class="status" :class="{ block: item.account_status !== 1 }">
                                    {{ item.account_status === 1 ? '正常' : '被封' }}
                                </span>
                                <span class="balance">${{ item.balance }}</span>
                                <!-- <span class="bm">021386327772377</span> -->
                            </div>
                        </el-radio>
                    </el-radio-group>
                    <el-pagination v-model:current-page="accountCurrentPage" :page-size="5"
                        layout="total, prev, pager, next" :total="accountTotal"
                        @current-change="handleAccountCurrentChange" />
                </div>
                <div class="list-footer">
                    <div v-if="currentStep === 0" class="add-button" @click="checkNetwork">新增Facebook账号授权</div>
                    <div @click="currentStep--" v-else class="last-button">上一步</div>
                    <div v-if="currentStep === 0" @click="handleNext" class="next-button">下一步</div>
                    <el-button v-else class="confirm-button" :disabled="accountRadio === null" @click="handleSubmit"
                        :type="accountRadio !== null ? 'primary' : null">确 定</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import api from '@/request/api/ad'
import financeApi from '@/request/api/finance'
import { ElMessage, ElLoading } from 'element-plus'

window.fbAsyncInit = function () {
    FB.init({
        appId: '1342273046428356',
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: 'v14.0', // Specify the Graph API version to use
    })
}
    // Load the SDK asynchronously
    ; (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')

const props = defineProps({
    id: {
        type: String,
    },
})

const { proxy } = getCurrentInstance()
const emit = defineEmits(['submit', 'close'])

const dialogRef = ref(null)
const isDialogShow = ref(true)
const currentStep = ref(0)
const loginStatus = ref(false)
const FBAccount = ref({
    name: '',
    url: '',
    accessToken: '',
    userID: '',
})
const FBUserList = ref([])
const FBAccountList = ref([])
const userRadio = ref(0)
const accountRadio = ref(null)
const accountSelected = ref(null)
const isLoginExpired = ref(false)
const loading = ref(false)
const networkStatus = ref(true)
const accountSearchValue = ref('')
const accountCurrentPage = ref(1)
const accountTotal = ref(1)

/** 刷新网络 */
const refreshNetwork = () => {
    const loading = ElLoading.service({
        target: dialogRef.value.$el.nextElementSibling.querySelector('.el-dialog'),
        text: '检测网络环境中...',
    })
    if (!window.FB) {
        networkStatus.value = false
        loading.close()
        return
    }
    FB.api('/me/permissions', (response) => {
        console.log('返回返回返回', response)
        if ((response && response.error && response.error.code === 2500) || (response && response.data)) {
            console.log('用户可以访问 Facebook')
            networkStatus.value = true
            loading.close()
        } else {
            console.log('用户无法访问 Facebook')
            networkStatus.value = false
            loading.close()
        }
    })
}

/** 检查是否连接外网 */
const checkNetwork = () => {
    api.template.handleEventTracking({ type: 7, source: localStorage.getItem('TemplateSource') })
    const loading = ElLoading.service({
        target: dialogRef.value.$el.nextElementSibling.querySelector('.el-dialog'),
        text: '检测网络环境中...',
    })
    if (!window.FB) {
        networkStatus.value = false
        loading.close()
        return
    }
    FB.api('/me/permissions', (response) => {
        console.log('返回返回返回', response)
        if ((response && response.error && response.error.code === 2500) || (response && response.data)) {
            console.log('用户可以访问 Facebook')
            networkStatus.value = true
            loading.close()
            FBLogin()
        } else {
            console.log('用户无法访问 Facebook')
            networkStatus.value = false
            loading.close()
        }
    })
}

/**
 * FB授权
 */
const FBLogin = () => {
    FB.login(
        (response) => {
            if (response.status === 'connected') {
                FB.api('/me?fields=id,name,picture', (res) => {
                    FBAccount.value.name = res.name
                    FBAccount.value.url = res.picture.data.url
                    FBAccount.value.accessToken = response.authResponse.accessToken
                    FBAccount.value.userID = response.authResponse.userID
                    if (response) {
                        postFBRes()
                        api.template.handleEventTracking({ type: 8, source: localStorage.getItem('TemplateSource') })
                    }
                })
            } else {
                // The person is not logged into your webpage or we are unable to tell.
                api.template.handleEventTracking({ type: 9, source: localStorage.getItem('TemplateSource') })
            }
        },
        { scope: 'public_profile,ads_management,pages_show_list,pages_read_engagement' }
    )
}

/**
 * 授权登录并更新本地已授权用户列表
 */
const postFBRes = async () => {
    const res = await api.template.FBLogin({
        token: FBAccount.value.accessToken,
        user_id: FBAccount.value.userID,
        name: FBAccount.value.name,
        url: FBAccount.value.url,
    })
    if (res.code === 200) {
        loginStatus.value = true
        if (isLoginExpired.value && FBUserList.value[userRadio.value].id !== FBAccount.value.userID)
            ElMessage.warning('当前授权的个号与已选的个号不一致')
        if (FBUserList.value.findIndex((item) => item.id.toString() === FBAccount.value.userID) === -1) {
            // 本地未授权过新增用户
            FBUserList.value.push({
                id: FBAccount.value.userID,
                name: FBAccount.value.name,
                url: FBAccount.value.url,
                account_num: res.account_num,
            })
        } else {
            // 本地已授权过更新用户信息
            const index = FBUserList.value.findIndex((item) => item.id.toString() === FBAccount.value.userID)
            FBUserList.value[index].name = FBAccount.value.name
            FBUserList.value[index].url = FBAccount.value.url
            FBUserList.value[index].account_num = res.account_num
        }
        localStorage.setItem('AdTemplateFBAuthUser', JSON.stringify(FBUserList.value))
        userRadio.value = FBUserList.value.findIndex((item) => item.id.toString() === FBAccount.value.userID)
        if (isLoginExpired.value) {
            getAccountList()
            currentStep.value += 1
            isLoginExpired.value = false
        }
    }
}

/** 判断所选用户授权是否过期并下一步 */
const handleNext = () => {
    api.template.getFBAuthExpire(FBUserList.value[userRadio.value].id).then((res) => {
        if (res.code === 200) {
            getAccountList()
            currentStep.value += 1
        } else {
            isLoginExpired.value = true
            checkNetwork()
        }
    })
}

/** 已授权用户切换广告账户（模板详情调用） */
const changeAccount = (account_id) => {
    if (account_id) accountSelected.value = account_id
    const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
    userRadio.value = FBUserList.value.findIndex((item) => item.id.toString() === user.fb_user_id)
    handleNext()
}

/** 获取FB用户所有广告账户 */
const getAccountList = () => {
    loading.value = true
    // 根据已选账户默认勾选
    if (accountSelected.value) accountSearchValue.value = accountSelected.value
    api.template
        .getFBAdAccount(FBUserList.value[userRadio.value].id, accountCurrentPage.value, accountSearchValue.value)
        .then((res) => {
            FBAccountList.value = res.data
            accountTotal.value = res.total
        })
        .finally(() => {
            loading.value = false
        })
}

/** 重置广告账户列表 */
const resetAccountList = () => {
    accountSearchValue.value = ''
    accountCurrentPage.value = 1
    accountSelected.value = null
    getAccountList()
}

/**
 * 广告账户分页切换
 * @param {Number} val 当前页码
 */
const handleAccountCurrentChange = (val) => {
    if (typeof val === 'number') accountCurrentPage.value = val
    getAccountList()
}

/** 确定授权用户和广告账户 */
const handleSubmit = () => {
    if (FBAccountList.value[accountRadio.value].balance <= 0 && proxy.$route.path !== '/ad_series') {
        financeApi.recharge().then((res) => {
            if (parseInt(res.data.balance) <= 0) {
                ElMessage({
                    dangerouslyUseHTMLString: true,
                    message: `账户余额不足，无法选择生成广告，<a href="${location.origin}/recharge">去充值钱包</a>`,
                    type: 'info',
                    showClose: true,
                    customClass: 'template-account-balance-message',
                    duration: 5000,
                })
            } else {
                ElMessage({
                    dangerouslyUseHTMLString: true,
                    message: `账户余额不足，无法选择生成广告，<a href="${location.origin}/advertising_list">去充值账户</a>`,
                    type: 'info',
                    showClose: true,
                    customClass: 'template-account-balance-message',
                    duration: 5000,
                })
            }
        })
        return
    }
    ElMessage.closeAll()
    api.template.handleEventTracking({ type: 10, source: localStorage.getItem('TemplateSource') })
    const user = {
        fb_user_id: FBUserList.value[userRadio.value].id,
        fb_name: FBUserList.value[userRadio.value].name,
        fb_avatar: FBUserList.value[userRadio.value].url,
        fb_token: FBAccount.value.accessToken,
    }
    localStorage.setItem('CurrentFBAuthUser', JSON.stringify(user))
    localStorage.setItem('AdTemplateAccountId', FBAccountList.value[accountRadio.value].account_id)
    emit('submit', user)
}

onMounted(() => {
    if (localStorage.getItem('AdTemplateFBAuthUser')) {
        loginStatus.value = true
        FBUserList.value = JSON.parse(localStorage.getItem('AdTemplateFBAuthUser'))
    } else {
        loginStatus.value = false
    }
})

defineExpose({
    changeAccount,
})
</script>

<style lang="less">
.template-login-dialog {
    .el-dialog__title {
        font-size: 18px;
        font-family: PingFang SC-Medium;
        font-weight: 500;
        color: #262626;
    }

    .el-dialog__body {
        min-height: 360px;
        padding: 0 40px 40px 40px;
    }
}

.template-account-balance-message {
    background-color: #ffffff;

    .el-message__content {
        font-size: 14px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
    }

    .el-icon-info {
        color: #409eff;
    }

    a {
        color: #409eff;
    }
}
</style>

<style lang="less" scoped>
.network-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        width: 320px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #595959;
    }

    .refresh-button {
        width: 64px;
        height: 32px;
        border-radius: 100px;
        border: 1px solid #2b82ff;
        font-size: 14px;
        font-family: PingFang SC-Regular;
        font-weight: 500;
        color: #2b82ff;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
        margin-top: 24px;
    }
}

.login-steps {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    .active-step {
        font-size: 16px;
        font-family: PingFang SC-Medium;
        font-weight: 500;
        color: #262626;
        line-height: 24px;
    }

    span {
        font-size: 16px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #595959;
        line-height: 24px;
        margin-left: 8px;
    }

    div {
        width: 120px;
        height: 0px;
        opacity: 1;
        border: 1px solid #f0f0f0;
        margin: 0px 40px;
    }

    .user-selected {
        position: absolute;
        bottom: -18px;
        left: 136px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
    }

    .account-selected {
        position: absolute;
        bottom: -20px;
        right: 66px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
    }
}

.login-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 161px;
    height: 40px;
    background: #2b82ff;
    border-radius: 100px;
    font-size: 14px;
    font-family: PingFang SC-Medium;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}

.list-container {
    .title {
        font-size: 14px;
        font-family: PingFang SC-Regular;
        font-weight: 500;
        color: #262626;
        margin-bottom: 16px;
    }

    .user-container {
        margin-bottom: 20px;

        :deep(.el-radio-group) {
            height: 208px;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background: #d9d9d9;
                border-radius: 8px;
            }
        }

        :deep(.el-radio) {
            width: 720px;
            height: 64px;
            border-radius: 4px;
            padding-left: 20px;
            margin-left: 0;
        }

        .user-radio {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin: 0 8px 0 32px;
            }

            .nickname {
                width: 150px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-right: 18px;
            }

            .id {
                width: 180px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-right: 30px;
            }

            span {
                font-family: PingFang SC-Regular;
                font-size: 14px;
                font-weight: 400;
                color: #595959;
                line-height: 64px;
            }
        }
    }

    .account-container {
        margin-bottom: 20px;

        .search {
            margin: 20px 0 20px 0;

            span {
                color: #595959;
                margin-right: 12px;
            }

            :deep(.el-input) {
                width: 288px;
                margin-right: 20px;
            }

            :deep(.el-button--default) {
                color: #595959;
            }

            :deep(.el-button) {
                font-family: PingFang SC-Regular;
            }
        }

        .header {
            width: 720px;
            height: 46px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #f0f0f0;
            margin-bottom: 4px;

            span {
                font-size: 14px;
                font-family: PingFang SC-Regular;
                font-weight: 400;
                color: #262626;
                line-height: 46px;
                display: inline-block;
            }

            &>span:nth-child(1) {
                width: 140px;
                margin-left: 64px;
                margin-right: 36px;
            }

            &>span:nth-child(2) {
                width: 180px;
                margin-right: 36px;
            }

            &>span:nth-child(3) {
                width: 70px;
                margin-right: 36px;
            }

            &>span:nth-child(4) {
                width: 120px;
                margin-right: 36px;
            }
        }

        :deep(.el-radio-group) {
            height: 262px;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background: #d9d9d9;
                border-radius: 8px;
            }
        }

        :deep(.el-radio) {
            width: 720px;
            height: 46px;
            border-radius: 4px;
            padding-left: 20px;
            margin-left: 0;
        }

        :deep(.el-pagination) {
            width: 100%;
            display: flex;

            .el-pagination__total {
                flex: 1;
            }

            .el-pager {
                color: #606266;
                font-weight: 500;
            }
        }

        .account-radio {
            display: flex;
            justify-content: center;
            align-items: center;

            .accountName {
                width: 140px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-right: 36px;
                margin-left: 20px;
            }

            .id {
                width: 180px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-right: 36px;
            }

            .status {
                width: 70px;
                margin-right: 36px;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background: #00f1a2;
                    border-radius: 50%;
                    margin-right: 8px;
                    margin-bottom: 2px;
                }
            }

            .block {
                &::before {
                    content: '';
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background: #f56c6c !important;
                    border-radius: 50%;
                    margin-right: 8px;
                    margin-bottom: 2px;
                }
            }

            .balance {
                width: 120px;
                margin-right: 36px;
            }

            .bm {
                width: 125px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            span {
                font-family: PingFang SC-Regular;
                font-size: 14px;
                font-weight: 400;
                color: #595959;
                line-height: 46px;
            }
        }
    }

    .list-footer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;

        div {
            background: #2b82ff;
            border-radius: 100px;
            font-size: 14px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
            color: #ffffff;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
        }

        .add-button {
            width: 189px;
            height: 40px;
        }

        .next-button {
            width: 82px;
            height: 40px;
        }

        .last-button {
            width: 82px;
            height: 40px;
        }

        :deep(.el-button.confirm-button) {
            width: 82px;
            height: 40px;
            border-radius: 100px;
            background: #2b82ff;
            font-size: 14px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
            color: #ffffff;
        }

        :deep(.is-disabled.confirm-button) {
            background: #ffffff;
            color: #a8abb2;
            opacity: 1;
            border: 1px solid #e4e7ed;
        }
    }
}

.balance-message {
    background-color: #ffffff;

    .el-message__content {
        font-size: 14px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
    }

    a {
        color: #409eff;
    }
}
</style>
